import { Injectable } from '@angular/core';
import PRIVILEGE from 'app/constants/privilege';
import ROLES from 'app/constants/roles';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  districts = [];
  taluks = [];
  selectedUser;
  token;
  isBackEnabled: boolean = false;
  searchParam;
  religion: [];
  caste: [];
  tradeUnions: [];
  subscriptionTypes: [];
  stepOneData;
  stepTwoData;
  stepThreeData;
  stepFourData;
  groups=[];

  // registrationSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private util: UtilsService
  ) { }

  setUser(data) {
    this.selectedUser = data;
  }
  getUser() {
    return this.selectedUser;
  }
  setBackNavigation(flag) {
    this.isBackEnabled = flag
  }
  getBackNavigation() {
    return this.isBackEnabled;
  }

  setSearchParam(param) {
    this.searchParam = param;
  }
  getSearchParam() {
    return this.searchParam;
  }

  getReligion() {
    return this.religion;
  }
  setReligion(data) {
    this.religion = data;
  }

  getTradeUnion() {
    return this.tradeUnions;
  }
  setTradeUnion(data) {
    this.tradeUnions = data;
  }

  getSubscriptionTypes() {
    return this.subscriptionTypes;
  }
  setSubscriptionTypes(data) {
    this.subscriptionTypes = data;
  }

  setStepOneData(data) {
    this.stepOneData = data;
  }
  setStepTwoData(data) {
    this.stepTwoData = data;
  }
  setStepThreeData(data) {
    this.stepThreeData = data;
  }
  setStepFourData(data) {
    this.stepFourData = data;
  }

  getStepOneData() {
    return this.stepOneData;
  }
  getStepTwoData() {
    return this.stepTwoData;
  }
  getStepThreeData() {
    return this.stepThreeData;
  }
  getStepFourData() {
    return this.stepFourData;
  }

  setGroups(groups) {
    this.groups = groups;
  }

  getGroups() {
    return this.groups
  }



  checkPrivilege(privilegeType) {
    let userPrivilege = localStorage.getItem('userPermissions') ? JSON.parse(localStorage.getItem('userPermissions')) : null;
    if (userPrivilege) {
      if (userPrivilege.indexOf(privilegeType) > 0) {
        return true
      } else {
        return false
      }
    } else {
      return null;
    }
  }


}
