import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icon: string;
  class: string;
  role: string
}
export const ROUTES: RouteInfo[] = [
  { type: 'normal', role: 'parent', path: '/dashboard', title: 'Dashboard', icon: 'pe-7s-graph', class: '' },
  { type: 'normal', role: 'parent', path: '/search/0', title: 'Member Search', icon: 'pe-7s-search', class: '' },
  { type: 'normal', role: 'parent', path: '/subscriptions', title: 'Subscription', icon: 'pe-7s-wallet', class: '' },
  { type: 'normal', role: 'parent', path: '/bulkSubscription', title: 'Bulk Subscription', icon: 'pe-7s-wallet', class: '' },
  { type: 'normal', role: 'parent', path: '/oldSubscription', title: 'Old Subscription', icon: 'pe-7s-wallet', class: '' },
  // { type: 'dp', role: 'parent', path: '/user', title: 'User Profile', icon: 'pe-7s-user', class: '' },
  { type: 'dp', role: 'parent', path: '/search/2', title: 'Member Profile', icon: 'pe-7s-user', class: '' },
  { type: 'normal', role: 'parent', path: '/register', title: 'Register', icon: 'pe-7s-add-user', class: '' },
  { type: 'normal', role: 'parent', path: '/cashbook', title: 'Cash Book', icon: 'pe-7s-notebook', class: '' },
  { type: 'normal', role: 'parent', path: '/reports', title: 'Reports', icon: 'pe-7s-note2', class: '' },
  { type: 'normal', role: 'parent', path: '/settings', title: 'Settings', icon: 'pe-7s-config', class: '' },
  // { path: '/table', title: 'Table List',  icon:'pe-7s-note2', class: '' },
  // { path: '/typography', title: 'Typography',  icon:'pe-7s-news-paper', class: '' },
  // { path: '/icons', title: 'Settings',  icon:'pe-7s-science', class: '' },
  // { path: '/maps', title: 'Maps',  icon:'pe-7s-map-marker', class: '' },
  // { path: '/notifications', title: 'Notifications',  icon:'pe-7s-bell', class: '' },
  // { path: '/upgrade', title: 'Upgrade to PRO',  icon:'pe-7s-rocket', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls:['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
}
