import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isDevMode } from '@angular/core';
import * as moment from 'moment';
import Swal from 'sweetalert2'
export enum RouterDirection { ROOT, FORWARD, REPLACE, SKIP }
import { Months } from 'app/data/months';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})

export class UtilsService {
  IS_DEBUG = isDevMode();
  months = Months;

  constructor(
    public router: Router,
    private ngxService: NgxUiLoaderService
  ) { }

  //Routing Manager
  route(type?: RouterDirection, path?: string) {
    if (type == RouterDirection.ROOT)
      this.router.navigate([path]);
    else if (type == RouterDirection.REPLACE)
      this.router.navigate([path], { replaceUrl: true, });
    else if (type == RouterDirection.FORWARD)
      this.router.navigate([path], { replaceUrl: true, });
    else if (type == RouterDirection.SKIP)
      this.router.navigate([path], { skipLocationChange: true });
  }

  getFinancialYear() {
    let month = new Date().getMonth() + 1;
    let startYear;
    let endYear;
    if (month >= 4) {
      startYear = new Date().getFullYear();
      endYear = new Date().getFullYear() + 1;
    } else {
      startYear = new Date().getFullYear() - 1;
      endYear = new Date().getFullYear();
    }
    return "FY" + startYear + "-" + endYear;
  }


  formatDate(date: string, format = 'YYYY-MM-DD'): string {
    return moment(date).format(format);
  }

  getAge(date: Date): number {
    return moment().diff(date, 'years', false);
  }

  reverseDate(date) {
    return typeof date === 'string' ? date.split("-").reverse().join("-") : date;
  }

  isEmpty(value: string): boolean {
    if (!value || value.toString().trim() == '' || value.toString().length == 0) return true;
    return false;
  }

  validateLength(limit: number, value: string): string {
    if (value.length > limit) {
      value = value.substr(0, limit);
    }
    return value;
  }

  showPopup(messageObj, title = "NOTITLE") {

    if (messageObj.message === 'HTML') {
      this.showHTMLMessage();
    } else if (messageObj.type == 'success') {
      Swal.fire(
        'Success',
        messageObj.message,
        'success'
      )
    } else if (messageObj.type == 'warn') {
      Swal.fire({
        icon: 'error',
        title: title == 'NOTITLE' ? 'Error ' : title,
        text: messageObj.message,
        // footer: '<a href>Why do I have this issue?</a>'
      })
    } else if (messageObj.type == 'info') {
      Swal.fire({
        icon: 'info',
        title: title == 'NOTITLE' ? 'For your Information' : title,
        text: messageObj.message,
        // footer: '<a href>Why do I have this issue?</a>'
      })
    }
  }

  showHTMLMessage() {
    let t = 'https://images.unsplash.com/photo-1481349518771-20055b2a7b24?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=809&q=80'
    Swal.fire({
      title: '<strong>HTML <u>example</u></strong>',
      // icon: 'info',
      html: `
      <div>
      <h3>test</h3>
      <p>para graph</p>
      <img src=${t}/>
      </div>
      `,
      // 'You can use <b>bold text</b>, ' +
      // '<a href="//sweetalert2.github.io">links</a> ' +
      // 'and other HTML tags',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText:
        'Close',
      confirmButtonAriaLabel: 'close',
      // cancelButtonText:
      //   '<i class="fa fa-thumbs-down"></i>',
      // cancelButtonAriaLabel: 'Thumbs down'
    })
  }

  // showToast(message: string, duration?: number) {
  //   this._snackBar.open(message, '', {
  //     duration: duration ? duration : 3000,
  //     horizontalPosition: this.horizontalPosition,
  //     verticalPosition: this.verticalPosition,
  //     panelClass: 'toastStyle'
  //   });
  // }

  showLoader() {
    this.ngxService.start();
    console.log("loader start");
  }

  dismissLoader() {
    this.ngxService.stop();
  }

  setLocalStorage(key, data) {
    localStorage.setItem(key, data);
  }

  getLocalStorage(key) {
    return localStorage.getItem(key);
  }

  getParsedLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  showPanels(value: boolean = false) {
    // this._fuseConfigService.config = {
    //   layout: {
    //     navbar: {
    //       hidden: value
    //     },
    //     toolbar: {
    //       hidden: value
    //     }
    //   }
    // };
    alert("Add a panel")
  }

  getMonthName(monthNumber) {
    let monthName;
    this.months.forEach((item, id) => {
      if (item.id == monthNumber) {
        monthName = item.name;
      }
    })
    return monthName;
  }

  isNumber(val): Boolean {
    let numberReg = /^\d+$/;
    return numberReg.test(val);
  }
  isMoneyAmount(val): Boolean {
    let numberReg = /^[0-9]+(\.[0-9]{1,2})?$/;
    return numberReg.test(val);
  }

  log(key: string, data: any, isResponse = false) {
    if (this.IS_DEBUG) {
      if (isResponse) {
        console.log(key.split('/')[key.split('/').length - 1], data);
      } else {
        console.log(key, data);
      }
    }
  }

}

