import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataService } from "./data.service";
import { UtilsService } from "./utils.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  BASEURL = "https://api.karshakathozhilali.org/api/";
  // BASEURL = "https://phptest.karshakathozhilali.org/api/";
  // BASEURL = 'http://demo.equityequations.com/api/';

  URL_DISTRICT = this.BASEURL + "utilities/district";
  URL_TALUK = this.BASEURL + "utilities/taluk/";
  URL_VILLAGE = this.BASEURL + "utilities/village/";

  URL_UPLOAD_FORM = this.BASEURL + "kswf/applications";
  URL_SEARCH_FORM = this.BASEURL + "kswf/applications/search";
  URL_VALIDATE_AADHAR_NO =
    this.BASEURL + "kswf/applications/adhaar?adhaarNumber=";

  //Phase 2 apis
  URL_LOGIN_ADMIN = this.BASEURL + "login";
  URL_SEARCH_MEMBER = this.BASEURL + "searchMember";
  URL_FETCH_OR_UPDATE_USER = this.BASEURL + "fetchORupdateCustomerDetails";
  URL_SUBSCRIPTION_MASTER_DETAILS = this.BASEURL + "subscriptionMasterDetails";
  URL_ADD_SUBCRIPTION_DETAILS = this.BASEURL + "addSubscriptionDetails";
  URL_SUBSCRIPTION_DETAILS = this.BASEURL + "subscriptionDetails";
  URL_BULK_SUBSCRIPTION_DETAILS = this.BASEURL + "bulkSubscriptionDetails";
  URL_DELETE_SUBSCRIPTION = this.BASEURL + "deleteSubscription";
  URL_FETCH_SUBSCRIPTION_REPORT = this.BASEURL + "fetchSubscriptionReport";
  // URL_FETCH_TALUK =this.BASEURL+'taluks';
  URL_FETCH_TALUK = this.BASEURL + "districts";
  URL_FETCH_VILLAGE = this.BASEURL + "taluks";
  URL_FETCH_DASHBOARD_ITEMS = this.BASEURL + "dashboard-stats";
  URL_FETCH_OPTIONS = this.BASEURL + "all-options";
  URL_SEARCH_SUBSCRIPTION = this.BASEURL + "memberByRegNo";
  URL_FETCH_USERS = this.BASEURL + "users";
  URL_STORE_USER = this.BASEURL + "users/store";
  URL_EDIT_USER = this.BASEURL + "users/update";
  URL_DELETE_USER = this.BASEURL + "users/delete";
  URL_FETCH_DASHBOAD_EXTRA_STATS = this.BASEURL + "statelevel-stats";
  URL_FETCH_DASHBOAD_SEARCH = this.BASEURL + "districtwise-subscriptiondetails";
  URL_FETCH_SUB_TYPES = this.BASEURL + "subscription-types";
  URL_ADD_OLD_SUBSCRIPTION = this.BASEURL + "addOldSubscriptionDetails";
  //Accounts
  URL_ADD_LEDGER = this.BASEURL + "ledger-create";
  URL_FETCH_ALL_TRANSACTION_TYPE = this.BASEURL + "transaction-types";
  URL_VIEW_ALL_ACCOUNT_GROUP = this.BASEURL + "account-group-all";
  URL_CREATE_ACCOUNT_GROUP = this.BASEURL + "account-group-create";
  URL_UPDATE_ACCOUNT_GROUP = this.BASEURL + "account-group-update";
  URL_DELETE_ACCOUNT_GROUP = this.BASEURL + "account-group-delete";

  URL_VIEW_ALL_LEDGER_ACCOUNT = this.BASEURL + "ledger-show";
  URL_LIST_ALL_LEDGER_ACCOUNT = this.BASEURL + "ledger-list";
  URL_CREATE_LEDGER_ACCOUNT = this.BASEURL + "ledger-create";
  URL_UPDATE_LEDGER_ACCOUNT = this.BASEURL + "ledger-update";
  URL_DELETE_LEDGER_ACCOUNT = this.BASEURL + "ledger-delete";
  URL_LIST_ACCOUNT_CHART = this.BASEURL + "accounts-chart";

  constructor(
    private http: HttpClient,
    private data: DataService,
    private utils: UtilsService
  ) {}

  //POST API METHOD
  post(url: string, data: any) {
    var reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
    });
    return new Promise((resolve) => {
      this.http.post(url, data, { headers: reqHeader }).subscribe(
        (res) => {
          resolve(res);
          this.utils.log(url, res, true);
        },
        (err) => {
          // this.utils.showPopup(JSON.stringify(err));
          this.utils.log(url, err, true);
          resolve(err);
          this.utils.dismissLoader();
        }
      );
    });
  }

  //POST Secure
  postSecure(url: string, data: any) {
    var reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    });
    return new Promise((resolve) => {
      this.http.post(url, data, { headers: reqHeader }).subscribe(
        (res) => {
          resolve(res);
          this.utils.log(url, res, true);
        },
        (err) => {
          this.utils.showPopup(JSON.stringify(err));
          this.utils.log(url, err, true);
          resolve(err);
          this.utils.dismissLoader();
        }
      );
    });
  }

  //PUT API METHOD
  put(url: string, data: any) {
    var reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });
    return new Promise((resolve) => {
      this.http.put(url, data, { headers: reqHeader }).subscribe(
        (res) => {
          resolve(res);
          this.utils.log(url, res, true);
        },
        (err) => {
          this.utils.log(url, err, true);
          resolve(err);
          this.utils.dismissLoader();
        }
      );
    });
  }

  //GET API METHOD
  get(url: string) {
    return new Promise((resolve) => {
      this.http.get(url).subscribe(
        (res) => {
          resolve(res);
          this.utils.log(url, res, true);
        },
        (err) => {
          this.utils.log(url, err, true);
          resolve(err);
          this.utils.dismissLoader();
        }
      );
    });
  }

  getSecure(url: string) {
    var reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    });

    return new Promise((resolve) => {
      this.http.get(url, { headers: reqHeader }).subscribe(
        (res) => {
          resolve(res);
          this.utils.log(url, res, true);
        },
        (err) => {
          this.utils.log(url, err, true);
          resolve(err);
          this.utils.dismissLoader();
        }
      );
    });
  }

  //------------------------Common API Methods---------------------------//
  //method to get list of districts
  loadDistricts() {
    if (this.data.districts.length > 0) return;
    this.get(this.URL_DISTRICT).then((response: any) => {
      if (response["success"] === true) {
        this.data.districts = response["district"];
      } else {
        // this.utils.showToast('Please try again later');
        this.utils.showPopup("Please try again later");
      }
    });
  }

  //method to get list of taluks
  loadTaluks(district: { district_id: string }) {
    if (!district && !district.district_id) return;
    this.utils.showLoader();
    this.get(this.URL_TALUK + district.district_id).then((response: any) => {
      if (response["success"] === true) {
        this.data.taluks = response["taluk"];
      } else {
        // this.utils.showToast('Please try again later');
        this.utils.showPopup("Please try again later");
      }
      this.utils.dismissLoader();
    });
  }
}
