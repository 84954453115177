export const Months =[
    {
        id:'01',
        name:'JAN'
    },
    {
        id:'02',
        name:'FEB'
    },
    {
        id:'03',
        name:'MAR'
    },
    {
        id:'04',
        name:'APR'
    },
    {
        id:'05',
        name:'MAY'
    },
    {
        id:'06',
        name:'JUN'
    },
    {
        id:'07',
        name:'JUL'
    },
    {
        id:'08',
        name:'AUG'
    },
    {
        id:'09',
        name:'SEP'
    },
    {
        id:'10',
        name:'OCT'
    },
    {
        id:'11',
        name:'NOV'
    },
    {
        id:'12',
        name:'DEC'
    }
];
